.container{
    overflow: hidden;
    background-color: #1E3130;
    position: relative;
    height: 100%;
    flex-grow: 1;
    border-radius: 16px;
}

:root {
    --fullscreen-icon-size: 105px;
}

.zoomOut {
    width: var(--fullscreen-icon-size);
    cursor: pointer;
    position: absolute;
    bottom: 90px;
    left: 0;
}

.selectionDisabled {
    pointer-events: none;
}

.tapEffectDisabled {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}