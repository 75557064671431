.contentRoot {
  width: var(--content-width);
  height: var(--main-content-height);
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(var(--scale));
  transform-origin: center;
  display: flex;
  align-items: center;
}