.container {
    overflow: hidden;
    background: #000 url("../../images/background-home.svg") no-repeat top right;
    position: relative;
    flex-grow: 1;
    border-radius: 16px;
    background-size: cover;
}

.overlay {
    z-index: 10;
}

.whiteModeContainer {
    background-color: #fff;
    width: 726px;
    height: 765px;
    border-radius: 20px;
    position: absolute;
    top: 71px;
    bottom: 202px;
    right: 143px;
    left: 515px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 52px;
    padding-right: 28px;
    padding-left: 28px;
}

.modeChoiceContainer {
    display: flex;
    flex-direction: column;
}

.chooseModeText {
    width: 397px;
}

.arrowsContainer {
    display: flex;
    justify-content: center;
}


.scoresButton {
    height: 88px;
    background-color: #9FA250;
}

.borderSelection{
    display: flex;
    align-items: center;
    border: 4px solid #000;
    border-radius: 20px;
}