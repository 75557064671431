.contentRoot {
    width: var(--content-width);
    height: var(--content-height);
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(var(--scale));
    transform-origin: center;
    display: flex;
    align-items: center;
}

.sidebar {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    align-items: center;
    flex-shrink: 0;
}

.logoHomeButton {
    width: 100%;
    cursor: pointer;
    display: flex;
}

.powLogo {
    width: 293px;
    margin-top: 8px;
}

.openMenuContainer {
    background-color: #fff;
    padding: 15px;
    margin-right: 22px;
    border-bottom-right-radius: 25px;
}

.openMenuButton {
    width: 67px;
    margin-right: 18px;
}

.logoHooPow {
    width: 208px;
    align-items: center;
}
