@tailwind base;
@tailwind components;
@tailwind utilities;

/** {*/
/*  box-sizing: border-box;*/
/*}*/

/* .contentRoot {

  width: 1821px;
  height: 1062.2659px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(var(--scale));
  transform-origin: center;
  display: flex;
  align-items: center;
  justify-content: center;
} */


:root {
  --scale: 1;

  /*--content-width: 1821px;
  --content-height: 1069px;*//*à remettre*/

  /* --content-width: 1866px;
  --content-height: 1069px; */

  /* --sidebar-width: 394px; */
  --sidebar-width: 442px;
  /* --sidebar-width: 364px; */

  --content-margin: 25px;
}

.ignore-scale {
  transform: scale(calc(1/var(--scale)));
}

/*body {*/
/*  margin: 0;*/
/*  font-family: "Avenir Black", sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  background-color: black;*/
/*  overflow: hidden;*/
/*}*/


body {
  user-select: none;
  background-color: #1c1d1e;
}

