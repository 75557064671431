@import "../../../../common.scss";

.hp-blockedModal-popup__unblock-forFree-banner {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: responsiveSizeToV2(91);
  font-size: responsiveSizeToV2(34);
  font-family: 'Avenir';
  font-weight: 200;

  background-color: #EEF2F4;
}

.hp-blockedModal-popup__unblock-forFree {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Fredoka One';
  color: #51AABC;
  .hp-blockedModal-popup__unblock-forFree-left {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .hp-blockedModal-popup__unblock-forFree-text {
      text-align: center;
      width: responsiveSizeToV2(337);
      font-size: responsiveSizeToV2(34);
    }
    .mainBtn {
      width: responsiveSizeToV2(304);
      height: responsiveSizeToV2(60);
      font-size: responsiveSizeToV2(25);
      margin-top: responsiveSizeToV2(20);
      border-radius: responsiveSizeToV2(10);
    }
  }

  .hp-blockedModal-popup__unblock-forFree-right {
    width: 58%;
    margin-right: 2%;
    img {
      width: 100%;
      height: auto;
    }
  }
}