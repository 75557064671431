@function responsiveHooPowMenu($sizeInPixel, $ratio: 1) {
	$unit: px;
	@return calc(#{$sizeInPixel} / 1038 * var(--mainContentHeight));
}

.hp-menu-icon-v3 {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: responsiveHooPowMenu(324.95);
	&--empty {
		background-color: #323334;
		width: responsiveHooPowMenu(96.39);
		height: responsiveHooPowMenu(96.39);
		border-radius: responsiveHooPowMenu(10);
		margin-bottom: responsiveHooPowMenu(20);
	}
	&--link {
		margin-bottom: responsiveHooPowMenu(20);
		cursor: pointer;
		width: responsiveHooPowMenu(96.39);
		height: responsiveHooPowMenu(96.39);
		display: inline-block;
		position: relative; 
		z-index: 1;
		span {
			object {
				z-index: -1;
				width: 100%;
				height: 100%;
				cursor: pointer;
				position: relative; 
			}
		}
	}
}