/* .container{
    overflow: hidden;
    background: url("../../images/background-home.svg") no-repeat top right;
    position: relative;
    height: 100%;
    flex-grow: 1;
}
 */

 .container{
     background-color: #fff;
     height: 100%;
     flex-grow: 1;
     border-radius: 16px;

 }

 :root {
    --fullscreen-icon-size: 105px;
}

.zoomOut {
    width: var(--fullscreen-icon-size);
    cursor: pointer;
    position: absolute;
    /* bottom: 180px; */
    /* left: 25px; */
    left: calc( -1 * var(--fullscreen-icon-size) - 25px);
    bottom: 94px;
}

.tapEffectDisabled {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.selectDisable {
    /* -khtml-user-select: none;
    -o-user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none; 
    -moz-user-select: none;
    -ms-user-select: none; 
    user-select: none; */
    pointer-events: none;
}