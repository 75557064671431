.freeBdImage{
    width: 100%;
    margin-top: 26px;
    margin-bottom: 77px;
}

.muslimShowImage{
    width: 326px;
}

.freeBd{
    background-color: #51aabc;
    color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 124px;
    margin-top: 26px;
    margin-bottom: 77px;
    border-radius: 12px;
    text-align: center;
    cursor: pointer;
}

.freeBdTitle{
    margin-top: 20px;
    font-size: 21px;
    font-family: "Lato similar Bilo", sans-serif;
}

.freeBdSubtitle{
    font-size: 25px;
    font-family: "Inter", sans-serif;
    font-weight: bold;
}

.zoomInContainer{
    width: 281px;
    height: 62px;
    margin-bottom: 28px;
    cursor: pointer;
    border: 3px solid #fff;
    border-radius: 30px;
    display: flex;
    align-items: center;
    padding-left: 30px;
}

.zoomInIcon{
    width: 36px;
    margin-right: 15px;
}

.zoomInText{
    font-size: 24px;
    color: #fff;
}

.homeContainer{
    width: 281px;
    height: 62px;
    cursor: pointer;
    border: 3px solid #fff;
    border-radius: 30px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    text-decoration: none;
}

.homeIcon{
    width: 32px;
    margin-right: 25px;
}

.homeText{
    font-size: 24px;
    color: #fff;
}

.otherButtonsSidebar{
    margin-top: auto;
    text-align: center;
    padding-bottom: 76px;

}