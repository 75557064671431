@font-face {
    font-family: 'Avenir';
    src: url('Avenir-Black.woff2') format('woff2'),
        url('Avenir-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Bold.woff2') format('woff2'),
    url('Inter-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-ExtraBold.woff2') format('woff2'),
    url('Inter-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fredoka One';
    src: url('FredokaOne-Regular.woff2') format('woff2'),
    url('FredokaOne-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Baloo Bhaijaan';
    src: url('BalooBhaijaan-Regular.woff2') format('woff2'),
    url('BalooBhaijaan-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato similar Bilo';
    src: url('Lato-Regular.woff2') format('woff2'),
    url('Lato-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'KG Blank Space Solid';
    src: url('KGBlankSpaceSolid.woff2') format('woff2'),
        url('KGBlankSpaceSolid.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Marvin Round';
    src: url('MarvinRound.woff2') format('woff2'),
        url('MarvinRound.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mali';
    src: url('Mali-Medium.woff2') format('woff2'),
        url('Mali-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mali';
    src: url('Mali-Regular.woff2') format('woff2'),
        url('Mali-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mali';
    src: url('Mali-SemiBold.woff2') format('woff2'),
        url('Mali-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mali';
    src: url('Mali-Bold.woff2') format('woff2'),
        url('Mali-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Courier';
    src: url('CourierBOLD.woff2') format('woff2'),
        url('CourierBOLD.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

