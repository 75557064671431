@import "../../../../common.scss";

$menu-icon-color: white;
$menu-icon-background-color: #1c1d1e;

@function responsiveMenuSize($sizeInPixel) {
  @return calc(#{$sizeInPixel} * var(--headerHeight) / 85);
}

.hp-layoutV2 {
  overflow: hidden;
  #mainContentScreen {
    z-index: 4;
  }
  .hp-menuV3.hp-menu-frame {
    position: absolute;
    width: 100%;
    margin-top: 4%;
    height: calc((var(--mainContentHeight) - var(--headerHeight)) * 0.95);
    border-radius: 0;
    background-color: #FFFFFF;
    border-top-left-radius: responsiveMenuSize(34);
    border-bottom-left-radius: responsiveMenuSize(34);
    transition: transform 0.5s ease-in-out;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    transform: translateX(101%);
    &.open {
      transform: translateX(0%);
    }
    .hp-menu-frame-profil-separator {
      width: 100%;
      height: 1px;
      background-color: #D8D8D8;
    }
    .hp-menu-frame-profil {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: responsiveMenuSize(320);
      .hp-menu-frame-profil-icon {
        cursor: pointer;
        position: relative;
        border: 1px solid #C3C3C3;
        &, img {
          height: responsiveMenuSize(150);
          width: responsiveMenuSize(150);
          border-radius: responsiveMenuSize(150);
        }
        .hp-menu-frame-profil-edit-icon {
          cursor: pointer;
          position: absolute;
          top: responsiveMenuSize(7);
          right: responsiveMenuSize(-17);
          height: responsiveMenuSize(50);
          width: responsiveMenuSize(50);
        }
      }
      h3 {
        font-size: responsiveMenuSize(26);
        margin-top: responsiveMenuSize(15);
        margin-bottom: responsiveMenuSize(5);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
        text-align: center;
      }
      span {
        font-family: 'Avenir';
        font-size: responsiveMenuSize(18);
      }
      button {
        color: #AAAAAA;
        cursor: pointer;
        font-family: 'Avenir';
        font-weight: 700;
        border: 1px solid #AAAAAA;
        background-color: transparent;
        height: responsiveMenuSize(32);
        width: responsiveMenuSize(161);
        font-size: responsiveMenuSize(16);
        margin-top: responsiveMenuSize(12);
        border-radius: responsiveMenuSize(12);
      }
    }
    .hp-menu-frame-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: responsiveMenuSize(17);
      margin-bottom: responsiveMenuSize(17);
    }

    .hp-menu-frame-icon-title {
      display: flex;
      align-items: center;
      text-decoration: none;
      margin-left: responsiveMenuSize(25);
      background-color: transparent;
      border: none;
      cursor: pointer;
      &:not(:first-child) {
        margin-top: responsiveMenuSize(13);
      }
      svg {
        width: responsiveMenuSize(48);
        height: responsiveMenuSize(48);
      }
      span {
        color: #1C1D1E;
        font-weight: 400;
        font-family: 'Avenir';
        text-decoration: none;
        font-size: responsiveMenuSize(22);
        margin-left: responsiveMenuSize(15);
      }
    }
  }
  .hp-menuV3-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: responsiveMenuSize(69);

    svg.hp-menu-icon-profil, img.hp-menu-icon-profil {
      width: auto;
      height: calc(var(--headerHeight)*.8);
      width: calc(var(--headerHeight)*.8);
    }
    img.hp-menu-icon-profil {
      border-radius: responsiveMenuSize(15);
    }
    .hp-menu-links {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: responsiveMenuSize(10);
      a.hp-menu-link-hoopow {
        margin-top: responsiveMenuSize(-8);
        height: responsiveMenuSize(45);
        svg.hp-menu-logo {
          cursor: pointer;
          width: auto;
          height: responsiveMenuSize(45);
        }
      }
      .hp-menu-links-auth {
        display: flex;
        color: white;
        font-weight: 900;
        font-family: 'Avenir';
        button {
          color: white;
          cursor: pointer;
          font-weight: 900;
          font-family: 'Avenir';
          font-size: responsiveMenuSize(20);
          line-height: 1;
          background-color: transparent;
          border: none;
          padding: 0;
          text-decoration: underline;
        }
        span {
          line-height: 1;
          font-size: responsiveMenuSize(20);
          max-width: responsiveMenuSize(250);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .mainBtn {
          display: flex;
          line-height: 1;
          align-items: center;
          text-decoration: none;
          justify-content: center;
          background-color: #51AABC;
          padding-top: 1px;
          border: 1px solid white;
          width: responsiveMenuSize(134);
          height: responsiveMenuSize(26);
          font-size: responsiveMenuSize(14);
          border-radius: responsiveMenuSize(26);
        }
        .empty {
          height: responsiveMenuSize(26);
        }
      }
    }
    .hp-menu-nav-frame {
      height: responsiveMenuSize(65);
      width: responsiveMenuSize(65);
      background-color: $menu-icon-background-color;
      margin-top: responsiveMenuSize(10);
      margin-bottom: responsiveMenuSize(10);
      margin-left: 0;
      border-radius: responsiveMenuSize(18);
      display: flex;
      justify-content: center;
      align-items: center;
      .hp-menu-nav-icon {
        margin-top: 30%;
        width: 65%;
        height: 70%;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: transform .35s ease-in-out;
        -moz-transition: transform .35ss ease-in-out;
        -o-transition: transform .35ss ease-in-out;
        transition: transform .35ss ease-in-out;
        cursor: pointer;
        span {
          display: block;
          position: absolute;
          height: 0px;
          border-top: 2px solid $menu-icon-color;
          border-bottom: 1px solid $menu-icon-color;
          width: 100%;
          background-color: $menu-icon-color;
          border-radius: responsiveMenuSize(5);
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: transform .35ss ease-in-out;
          -moz-transition: transform .35s ease-in-out;
          -o-transition: transform .35s ease-in-out;
          transition: transform .35s ease-in-out;
        }
        span:nth-child(1) {
          top: 0;
        }

        span:nth-child(2), span:nth-child(3) {
          top: 26%;
        }

        span:nth-child(4) {
          top: 52%;
        }

        &.open span:nth-child(1) {
          top: 26%;
          width: 0%;
          left: 50%;
        }

        &.open span:nth-child(2) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        &.open span:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

        &.open span:nth-child(4) {
          top: 26%;
          width: 0%;
          left: 50%;
        }
      }
    }
  }
}
