@import "../../../../common.scss";

.ms-home-helpus {
	margin-top: responsiveSizeToV2(60);
	height: auto;
	width: responsiveSizeToV2(325.53);
	cursor: pointer;
}

.ms-home-icon-v3 {
	flex: 1;
	width: 100%;
	display: flex;
	justify-content: center;
	height: responsiveSizeLegacyToV2(146.11);
	svg {
		height: auto;
		width: responsiveSizeLegacyToV2(360);
	}
}
